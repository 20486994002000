
import React from 'react';
import Header from "../../components/Header/index"
import Footer from "../../components/Footer"
import './melhorias.css';
import 'animate.css';
import atendente from "../../assets/atendente.jpg"




const Melhorias = () => {

        

    return (
        <>
            <Header />

            <section id="header_gestao">

                <h2><span>Melhorias</span> e atualizações da plataforma!</h2>
                <p>Todas as melhorias e atualizações implementadas em nossa plataforma serão divulgadas aqui!</p>

            </section>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 py-3">
                            <center><h5>Melhorias realizadas:</h5></center>
                            <ul>
                                <li>Cadastro de endereços facilitado por CEP.&nbsp;<span><i class="fa fa-check green"></i></span></li> 
                                <li>Integração com a plataforma Viva Real.&nbsp;<span><i class="fa fa-check green"></i></span></li>
                                <li>Possibilidade de anexar arquivos individuais para cada funcionário.&nbsp;<span><i class="fa fa-check green  "></i></span></li>
                                <li>Modernização e redesign do layout do sistema.&nbsp;<i class="fa fa-check green"></i></li>
                                <li>Aplicação de marca d'água em todas as fotos.&nbsp;<span><i class="fa fa-check green" ></i></span></li>

                            </ul>
                        </div>
                        <div className="col-md-6 py-3">
                            <center><h5>Proximas atualizações:</h5></center>
                            <ul>
                                <li>Integrações com novas plataformas de mercado imobiliário.&nbsp;<i class="fas fa-spinner fa-spin"></i></li>
                                <li>Desenvolvimento do aplicativo do sistema de gestão.&nbsp;<i class="fas fa-spinner fa-spin"></i></li>
                                <li>Desenvolvimento do painel de locatários.&nbsp;<i class="fas fa-spinner fa-spin"></i></li>
                                

                            </ul>
                        </div>

                        
                    </div>

                </div>

            </section>



            <section id="contato">
                <div className="container">
                    <center><h2 className="titulo">Não perca tempo entre em contato agora!</h2></center>
                    <br />
                    <div className="row">
                        <div className="col-md-5">
                            <img src={atendente} className="img-fluid " alt="" />
                        </div>
                        <div className="col-md-7 alinhamento">
                            <p>
                                "Descubra nosso incrível produto! Entre em contato agora mesmo e solicite um orçamento sem compromisso. Estamos ansiosos para atendê-lo e oferecer soluções personalizadas para suas necessidades. Não perca essa oportunidade, fale conosco hoje mesmo!"
                            </p>
                            <br>
                            </br>
                            <div className="_btn">
                                <big>
                                    <big className="color">
                                        <i class="fa-brands fa-whatsapp fa-beat fa-2xl" ></i>
                                    </big>
                                    <button className="m-3 btn_whats">
                                        <a href="https://api.whatsapp.com/send?1=pt_BR&phone=5511919267633">Faça seu orçamento</a>
                                    </button>
                                </big>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )

}
export default Melhorias